import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThemeService } from '../services/theme.service';

export const ThemeResolver: ResolveFn<Observable<void>> = () => {
  const themeService: ThemeService = inject(ThemeService);

  return <Observable<void>>themeService.init().pipe(
    catchError(() => {
      return of({});
    })
  );
};
