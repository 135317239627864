import { AuthGuard } from './core/guards/auth.guard'
import { ThemeResolver } from './core/resolvers/theme.resolver'
import { AuthResolver } from './core/resolvers/user.resolver'

import { Routes } from '@angular/router'

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    resolve: {
      theme: ThemeResolver,
    },
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesModule),
    canActivate: [AuthGuard],
    resolve: {
      theme: ThemeResolver,
      user: AuthResolver,
    },
  },
]
