import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private localStorageService: LocalStorageService = inject(LocalStorageService);
  private authService: AuthService = inject(AuthService);

  private addToken(httpRequest: HttpRequest<any>, token: string) {
    return httpRequest.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const split = request.url.split('/');
    // const isRefresh = split[split.length - 1].includes('refresh');
    const token = this.localStorageService.getItem('token');

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            this.authService.logout();
          }
          // if (error.status === 401 && token) {
          //     if (!isRefresh) {
          //         if (!this.authService.refreshInProgress) {
          //             this.authService.refresh().subscribe();
          //         }
          //     } else {
          //         this.authService.logout();
          //     }
          // }
        }
        return throwError(() => {
          return error;
        });
      })
    );
  }
}
